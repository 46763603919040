<template>
  <input class="form-control" :value="modelValue" @input="$emit('update:modelValue', $event.target.value)" ref="input" />
</template>

<script setup>
import { onMounted, ref } from 'vue'

defineProps(['modelValue'])

defineEmits(['update:modelValue'])

const input = ref(null)

onMounted(() => {
  if (input.value.hasAttribute('autofocus')) {
    input.value.focus()
  }
})
</script>
