import './bootstrap'
import '../assets/css/app.css'

import { createApp, h } from 'vue'
import { createInertiaApp } from '@inertiajs/inertia-vue3'
import { InertiaProgress } from '@inertiajs/progress'
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers'
import { ZiggyVue } from '../../vendor/tightenco/ziggy/dist/vue.m'
import globalcomponents from './GlobalComponents'
import utils from '../assets/utils'
import { createPinia } from 'pinia'
import { createGtm } from '@gtm-support/vue-gtm'
import { i18nVue } from 'laravel-vue-i18n'

const appName = window.document.getElementsByTagName('title')[0]?.innerText || 'Laravel'

createInertiaApp({
  title: (title) => `${title} - ${appName}`,
  resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
  setup({ el, app, props, plugin }) {
    const setupApp = createApp({ render: () => h(app, props) })
      .use(plugin)
      .use(ZiggyVue, Ziggy)
      .use(createPinia())
      .use(i18nVue, {
        fallbackLang: 'en',
        resolve: async (lang) => {
          const langs = import.meta.glob('../lang/*.json')
          return await langs[`../lang/${lang}.json`]()
        },
      })

    if (typeof props.initialPage.props.reservationPage !== 'undefined') {
      setupApp.use(
        createGtm({
          id: props.initialPage.props.reservationPage.gtmId,
          debug: false,
        }),
      )
    }

    const toggleLocale = async (locale) => {
      await axios
        .post(route('locale'), { locale: locale })
        .then((response) => {
          setupApp.use(i18nVue, {
            lang: response.data.props.locale,
          })
        })
        .catch((error) => {
          console.log(error)
        })
    }

    globalcomponents(setupApp)
    utils(setupApp)
    return setupApp.mount(el)
  },
})

InertiaProgress.init({ color: '#4B5563' })
